.form__fieldset {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
  border: none;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 720px) {
  .form__fieldset {
    margin-bottom: 18px;
  }
}
