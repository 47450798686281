.element__delete-button {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 19px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: url("../../../images/element__delete-button.svg");
  border: 0;
  transition: opacity .1s ease;
}

.element__delete-button:hover {
  opacity: .5;
  cursor: pointer;
}
