.header__logo {
  width: 104px;
  height: 25px;
}

@media screen and (min-width: 720px) {
  .header__logo {
    width: 142px;
    height: 33px;
  }
}
