.header__logo-text {
  align-self: center;
  padding: 0;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  color: white;
}

@media screen and (min-width: 720px) {
  .header__logo-text {
    font-size: 24px;
    line-height: 29px;
  }
}
