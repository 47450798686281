.page {
  position: relative;
  min-width: 320px;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', 'Helvetica', sans-serif;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
