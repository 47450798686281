.popup__container {
  position: relative;
  box-sizing: border-box;
  padding: 25px 22px;
  min-width: 282px;
  background: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: black;
}

@media screen and (min-width: 720px) {
  .popup__container {
    min-width: 430px;
    padding: 34px 36px 37px;
  }
}
