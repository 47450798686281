.form__submit-button_disabled {
  color: black;
  border: 1px solid #000000;
  background-color: transparent;
  opacity: .2;
}

.form__submit-button_disabled:hover {
  opacity: .2;
  cursor: default;
}
