.profile__add-button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
  background: transparent url('../../../images/profile__add-button.svg') no-repeat center / cover;
  background-size: 16px auto;
  transition: opacity .1s ease;
}

.profile__add-button:hover {
  opacity: .6;
  cursor: pointer;
}

@media screen and (min-width: 720px) {
  .profile__add-button {
    width: 150px;
    margin-left: auto;
    background-size: 22px auto;
  }
}
