.elements {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 18px;
  column-gap: 17px;
}

@media screen and (min-width: 720px) {
  .elements {
    grid-template-columns: repeat(2, calc((100% - 17px)/2));
  }
}

@media screen and (min-width: 1024px) {
  .elements {
    grid-template-columns: repeat(3, calc((100% - 17px*2)/3));
  }
}
