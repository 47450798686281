.link {
  color: inherit;
  text-decoration: none;
  opacity: 1;
  transition: opacity .1s ease;
}

.link:hover {
  opacity: .6;
  cursor: pointer;
}
