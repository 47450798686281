.element__image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
}

.element__image:hover {
  cursor: pointer;
}
