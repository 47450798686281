.auth__container {
  box-sizing: border-box;
  padding: 25px 0;
  min-width: 260px;
  color: white;
}

@media screen and (min-width: 720px) {
  .auth__container {
    min-width: 358px;
    padding: 60px 0 37px;
  }
}
