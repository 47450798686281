.element__name {
  padding: 0;
  margin: 0;
  margin-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 85%;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}
