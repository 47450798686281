.header__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  margin-top: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A9A9A9;
}

@media screen and (min-width: 720px) {
  .header__info {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    font-size: 18px;
    line-height: 22px;
  }
}
