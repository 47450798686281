.popup__close-button {
  padding: 0;
  margin: 0;
  position: absolute;
  top: -36px;
  right: 0;
  width: 20px;
  height: 20px;
  color: white;
  border: none;
  background-color: transparent;
  background: url("../../../images/popup__close-button.svg");
  transition: opacity .1s ease;
}

.popup__close-button:hover {
  opacity: .6;
  cursor: pointer;
}


@media screen and (min-width: 720px) {
  .popup__close-button {
    width: 32px;
    height: 32px;
    top: -40px;
    right: -40px;
  }
}
