.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 42px;
  padding-bottom: 36px;
}

@media screen and (min-width: 720px) {
  .profile {
    flex-direction: row;
    padding-top: 32px;
    padding-bottom: 42px;
  }
}
