.form__input-error {
  padding: 0;
  margin: 0;
  padding-top: 4px;
  display: block;
  width: 100%;
  max-width: 238px;
  min-height: 26px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #FF0000;
}

@media screen and (min-width: 720px) {
  .form__input-error {
    max-width: 358px;
  }
}
