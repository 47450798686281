.footer__copyright {
  padding: 0;
  margin: 0;
  margin-top: 48px;
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
}

@media screen and (min-width: 1024px) {
  .footer__copyright {
    margin-top: 67px;
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 22px;
  }
}
