.profile__image-edit-button {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .1s ease-in-out;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.8) url('../../../images/profile__edit-button.svg') no-repeat center / cover;
  background-size: 26px auto;
}

.profile__image-edit-button:hover {
  opacity: 1;
  cursor: pointer;
}
