.form__input {
  width: 100%;
  background: transparent;
  padding: 0;
  padding-bottom: 9px;
  margin: 0;
  font-family: inherit;
  color: black;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}
