.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 28px 27px 27px;
  border-bottom: 1px solid rgba(84, 84, 84, .7);
}

@media screen and (min-width: 720px) {
  .header {
    padding: 45px 0 35px;
  }
}
