.tooltip__text {
  padding: 0;
  margin: 0;
  max-width: 244px;
  margin-bottom: 13px;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

@media screen and (min-width: 720px) {
  .tooltip__text {
    margin-bottom: 23px;
    font-size: 24px;
    line-height: 29px;
    max-width: 358px;
  }
}
