.auth__title {
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

@media screen and (min-width: 720px) {
  .auth__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 50px;
  }
}
